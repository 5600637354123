<template>
    <div class="video-container">
        <div class="video-seat">
            <el-row type="flex" align="middle">
                <el-col :span="12">
                    <div class="elevator-name-span" :title="elevator['v_elevator_name']">{{ elevator && elevator['v_elevator_name'] }}</div>
                </el-col>
                <el-col :span="12">
                    <div class="btn-block" :style="{color: (isRecording ? 'red' : '')}" @click="isRecording ? stopRecord() : startRecord()">
                        <img :src="require('@/assets/ic_rec.png')" alt="" />开始录屏
                    </div>
                    <div class="btn-block" :style="{color: (isTalking ? 'red' : '')}" @click="isTalking ? stopTalk() : startTalk()">
                        <img :src="require('@/assets/ic_phone.png')" alt="" />发起对讲
                    </div>
                </el-col>
            </el-row>
            <div class="options" v-if="showMonitor">
                <!--         <div class="item active">
                    <img v-if="elevatorModuleInfo && elevatorModuleInfo.onLine === 0" src="@/assets/icon_offline_gray.png" />
                    <img v-else-if="elevatorModuleInfo && elevatorModuleInfo.onLine != 0" src="@/assets/bigscreen/ruijin/icon_online_green.png" />
                    <div class="title">{{ elevatorModuleInfo ? (elevatorModuleInfo.onLine === 0 ? '离线' : '在线') : '--' }}</div>
                </div> -->
                <div class="item">
                    <div v-if="monitorPageDisplay.floor.value" style="font-weight: bold; color: #3CD500;">{{ monitorPageDisplay.floor.value }}</div>
                    <div v-else style="font-weight: bold; color: #3CD500;">--</div>
                    <div class="title">楼层</div>
                </div>
                <div :class="monitorPageDisplay.direction && monitorPageDisplay.direction.value === 1 ? 'item' : 'item active'">
                    <img v-if=" monitorPageDisplay.direction.value === 0" src="@/assets/icon_park_green.png" />
                    <img v-else-if="monitorPageDisplay.direction.value === 1" src="@/assets/icon_up_yellow.png" />
                    <img v-else-if="monitorPageDisplay.direction.value === 2" src="@/assets/icon_down_yellow.png" />
                    <div v-else style="font-weight: bold; color: #3CD500;">--</div>
                    <div class="title">方向</div>
                </div>
                <div :class="monitorPageDisplay.floorStatus && monitorPageDisplay.floorStatus.value === 1 ? 'item' : 'item active'">
                    <img v-if="monitorPageDisplay.floorStatus.value === 1" src="@/assets/icon_pingc.png" />
                    <img v-else-if="monitorPageDisplay.floorStatus.value === 0" src="@/assets/icon_feipc.png" />
                    <div v-else style="font-weight: bold; color: #3CD500;">--</div>
                    <div class="title">平层状态</div>
                </div>
                <div class="item">
                    <img v-if="dangmen" src="@/assets/icon_alert.png" />
                    <img v-if="!dangmen" src="@/assets/icon_right_green.png" />
                    <div class="title">阻挡门</div>
                </div>
            </div>
        </div>
        <div class="video-poster" v-if="!isVideoInit">
            <img :src="require('@/assets/ic_eye.png')" alt="">
        </div>
        <div class="video-wrapper" id="video-wrapper">
            <div :id="domContainer" class="video-zone"></div>
        </div>
        <div class="op-bar">
            <div>
                <div class="btn play-btn">
                    <img v-if="!isPlaying" src="@/assets/ic_play.png" @click="onPlay" />
                    <img v-else src="@/assets/ic_stop.png" @click="onStop" />
                </div>
                <div class="btn capture-btn" title="截图">
                    <img src="@/assets/ic_capture.png" @click="onCapture" />
                </div>
            </div>
            <div>
                <div class="btn fullscreen-bt" title="全屏">
                    <img src="@/assets/ic_fullscreen.png" @click="fullScreen" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Loading } from 'element-ui';
import { VideoKit } from '@/common/utils/videoKit/index'
import { getElevatorLiveUrl, getElevatorMonitorUrlEzopenToken } from '@/api/monitor/index'
import Monitor from '@/pages/monitor/monitor'
export default {
    props: {
        elevator: {
            type: Object,
            required: false
        },
        num: {
            type: Number,
            required: false
        },
        showMonitor: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            haikangCamera: true,
            isTalking: false,
            isVideoOpen: false,
            currentCamera: {},
            privateUrlToken: '',
            showBtns: false,
            monitor_showloding_flag: false,
            monitorVidoeFlag: false,
            monitorRtmp: true,
            monitorHls: false,
            isVideoInit: false,
            domContainer: `video-${this.num}`,
            isRecording: false,
            dangmen: false,
            loadingInstance: null,

            videoKitRef: null,
            isPlaying: false,
        }
    },
    mixins: [Monitor],
    mounted() {
        this.videoKitRef = VideoKit()
    },
    methods: {

        addResizeDetector() {
            let elementResizeDetectorMaker = require("element-resize-detector");
            //监听元素变化
            let erd = elementResizeDetectorMaker();
            let that = this;
            erd.listenTo(document.getElementById('video-wrapper'), function(element) {

                let width = that.videoKitRef.calcSizeBySerialNum(that.currentCamera.vCloudNumber, element.offsetWidth, element.offsetHeight).width
                let height = that.videoKitRef.calcSizeBySerialNum(that.currentCamera.vCloudNumber, element.offsetWidth, element.offsetHeight).height

                let video = document.getElementById(`EZUIKitPlayer-video-${that.num}`)
                if (video)
                    video.style = `width:${width}px; height:${height}px;`

            })
        },
        getElevatorCode() {
            return this.currentCamera.vElevatorCode
        },
        startTalk() {
            this.videoKitRef.startTalk().then(() => {
                this.isTalking = true
            })
        },
        stopTalk() {
            this.videoKitRef.stopTalk().then(() => {
                this.isTalking = false
            })
        },
        startRecord() {
            if (this.isPlaying) {
                this.videoKitRef.startRecord();
                this.isRecording = true
            } else {
                this.$Message.error('请先打开视频再开始录制')
            }

        },
        stopRecord() {
            this.videoKitRef.stopRecord()
            this.isRecording = false
        },

        fullScreen() {
            this.videoKitRef.fullScreen()
        },

        async setupVideoAndDataMonitor(elevatorId) {
            try {
                this.loadingInstance = Loading.service()
                let elementResizeDetectorMaker = require("element-resize-detector");
                //监听元素变化
                let erd = elementResizeDetectorMaker();
                erd.uninstall(document.getElementById(this.domContainer))
                await this.videoKitRef.destroy(this.domContainer)
                this.isPlaying = false
            } finally {
                try {
                    const res = await getElevatorLiveUrl({ elevatorId: elevatorId })
                    if (res.info && this.showMonitor) {
                        this.closeConnection()
                        if (res.info)
                            this.initSocket(res.info.vElevatorCode, ['CarRoof', 'MontorRoom', 'SINGLEBOX'])
                    }
                    this.currentCamera = res.info
                    let dom = document.getElementById('video-wrapper')
                    let size = this.videoKitRef.calcSizeBySerialNum(this.currentCamera.vCloudNumber, dom.clientWidth, dom.clientHeight)
                    this.videoKitRef.initDisplay(this.domContainer, this.privateUrlToken, this.currentCamera.vCloudNumber, { width: size.width, height: size.height }, (result, data) => {
                        if (!result) {
                            this.isPlaying = false
                            this.$Message.error('初始化失败')
                        } else {
                            this.isPlaying = true
                        }
                    }).then((player) => {
                        console.log('初始化成功')
                        this.isVideoInit = true
                        this.addResizeDetector()
                        this.loadingInstance.close()
                    })
                } catch (e) {
                    this.isPlaying = false
                    this.isRecording = false
                    this.loadingInstance.close()
                }
            }
        },

        async onPlay() {
            try {
                this.isPlaying = true
                await this.videoKitRef.playVideo()
            } catch (err) {
                this.isPlaying = false
                this.loadingInstance.close()
            }
        },

        async onStop() {
            try {
                this.isPlaying = false
                await this.videoKitRef.stopVideo()
                if (this.isRecording) 
                    this.stopRecord()
            } catch (err) {
                this.isPlaying = false
                this.loadingInstance.close()
            }
        },

        async onCapture() {
            try {
                await this.videoKitRef.capturePicture()
            } catch (err) {
                this.$Message.error('截图失败')
            }
        },
    },
    created() {
        // 获取萤石云 监控token
        getElevatorMonitorUrlEzopenToken().then(res => {
            console.log("res 2", res)
            this.privateUrlToken = res.info
        })
    },
    beforeDestroy() {

    },

    destroy() {
        this.videoKitRef.destroy()
    },
    watch: {
        elevator(newData, oldData) {
            // let hasPlayer = videoKit.pickPlayer(this.domContainer)
            if (newData != null && newData != undefined) {
                this.setupVideoAndDataMonitor(newData['v_elevator_id'])
            } else {
                this.videoKitRef.destroy(this.domContainer)
            }
        },

        'videoKitRef.playing'(val) {

        }
    }
}
</script>
<style lang="less" scoped>
svg {
    display: none;
}

.video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #383C4B;
}

.btn-block {
    display: inline-block;
    float: right;
    color: #D6D8E4;
    font-size: 16px;
    background-color: #383C4B;
    border: 1px solid #606371;
    border-radius: 5px;
    width: 128px;
    height: 33px;
    line-height: 33px;
    margin-right: 8px;
    text-align: center;

}

.btn-block img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: sub;
}

.video-seat {
    width: 100%;
    height: auto;
    position: absolute;
    top: 1px;
    right: 1px;
    padding: 15px 8px;
    z-index: 999999;
    background-color: rgba(0, 0, 0, .3);
}

.video-poster {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.video-poster img {
    width: 90px;
    height: 90px;
}

.video-wrapper {
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}


.video-zone {
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.elevator-name-span {
    color: #fff;
    font-size: 20px;
    text-align: left;
    padding-left: 10px;
    white-space: nowrap;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.options {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    text-align: center;
    // background-image:url('@/assets/bigscreen/ruijin/options_bg.png');
    // background-size: 100% 100%;
    // background-color: #011145;
    background-size: auto 100%;

    &>.item {
        display: inline-block;
        padding: 0 20px;
        text-align: center;

        .radio {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 5px solid #fff;
            border-radius: 100%;
            background-color: #fff;
            overflow: hidden;
        }

        .title {
            font-size: 16px;
            color: #fff;
        }

        &.active {
            .radio {
                border-color: #de1717;
                background-color: #0d1b4c;
            }
        }
    }
}

.op-bar {
    position: absolute;
    width: 100%;
    left: 0;
    height: 60px;
    z-index: 9;
    bottom: 0;
}

/deep/.iframe-btn-containerr {
    display: none !important;
}

.op-bar {
    display: flex;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;
}

.op-bar div {
    display: flex;
    align-items: center;
}

.btn img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.btn {
    margin-right: 20px;
}

.btn:last-of-type {
    margin-right: 0px;
}

.capture-btn img {
    width: 40px;
    height: 40px;
}
</style>