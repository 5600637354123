import EZUIKit from 'ezuikit-js';
import { formatDateYMD } from '@/common/utils/date/index';

const ezopenBaseUrl = "ezopen://open.ys7.com";

export function VideoKit() {

    let player = null;
    let playing = false;

    function calcSizeBySerialNum(serialNum, clientWidth, clientHeight) {
        let ratio = 16 / 9
        let isPortarit = portraitCloudNumbers().indexOf(serialNum) > -1
        ratio = isPortarit ? 1 / ratio : ratio

        let clientRatio = clientWidth / clientHeight //当前播放容器的宽高比

        let width = clientWidth
        let height = clientHeight
        if (ratio > clientRatio) { //宽溢出以容器宽为主
            height = width / ratio
        } else {
            width = height * ratio
        }
        return {
            width,
            height
        }
    }

    function initDisplay(domContainer, accessToken, serialNum, option = {}, callback) {
        let _player = new EZUIKit.EZUIKitPlayer({
            autoplay: 0,
            audio: 1,
            id: domContainer,
            accessToken: accessToken,
            url: generateLiveUrl(serialNum, option),
            template: 'simple',
            plugin: option['talk'] || ['talk'],
            header: option['header'],
            footer: option['footer'],
            width: option.width,
            height: option.height,
            handleSuccess: data => {
                console.log("播放成功", data)
                playing = true
                if (callback) callback(true, data)
            },
            handleError: data => {
                console.log("播放失败", data)
                playing = false
                if (callback) callback(false, data)
            },
            openSoundCallBack: data => console.log("开启声音回调", data),
            closeSoundCallBack: data => console.log("关闭声音回调", data),
            startSaveCallBack: data => console.log("开始录像回调", data),
            stopSaveCallBack: data => console.log("录像回调", data),
            capturePictureCallBack: data => console.log("截图成功回调", data),
            fullScreenCallBack: data => console.log("全屏回调", data),
        })

        player = _player;

        return new Promise(function(resolve, reject) {
            if (player) {
                console.log("生成播放器", player)
                resolve(player)
            } else {
                console.log("found player instance")
                reject("not found player instance")
            }
        })
    }

    function generateLiveUrl(serialNum, option) {
        let url = `${ezopenBaseUrl}/${serialNum}/${option.channel || 1}.${option.quality != null ? option.quality + '.' : ''}${option.way || 'live'}`
        return url
    }

    function stopVideo() {
        console.log('点击停止播放')
        return new Promise((resolve, reject) => {
            if (!player) {
                playing = false
                console.log('找不到player')
                reject()
            } else {
                console.log('调用停止播放')
                player.stop().then((option) => {
                    console.log('停止播放调用成功')
                    playing = false
                    resolve()
                }).catch((err) => {
                    console.log('停止播放失败')
                    playing = false
                    reject()
                })
            }
        })
    }

    function playVideo() {
        console.log('点击播放')
        return new Promise((resolve, reject) => {
            if (!player) {
                playing = false
                console.log('找不到player')
                reject()
            } else {
                console.log('调用播放')
                player.play().then((option) => {
                    console.log('播放调用成功')
                    playing = true
                    resolve()
                }).catch((err) => {
                    console.log('播放失败')
                    playing = false
                    reject()
                })
            }
        })
    }

    function refreshVideo() {
        player.stop().then((option) => {
            console.log("视频播放停止成功！", option)
            player.play().then((option) => {
                console.log("视频播放成功", option)
            });
        });
    }

    function startTalk() {
        player.startTalk()
        return new Promise(function(resolve, reject) {
            if (player) {
                resolve()
            } else {
                reject("not found player instance")
            }
        })
    }

    function stopTalk() {
        player.stopTalk()
        return new Promise(function(resolve, reject) {
            if (player) {
                resolve()
            } else {
                reject("not found player instance")
            }
        })
    }

    function startRecord() {
        player.startSave(`record-${formatDateYMD(new Date(), 'yyyyMMddHHmmss')}`);
    }

    function stopRecord() {
        player.stopSave();
    }

    function capturePicture() {
        return new Promise(async (resolve, reject) => {
            if (!player) {
                playing = false
                console.log('找不到player')
                reject()
            } else {
                try {
                    await player.capturePicture()
                    console.log('截图调用成功')
                    resolve()
                } catch (err) {
                    console.log('截图失败')
                    reject()
                }
            }
        })
    }

    function fullScreen() {
        player.fullScreen()
    }

    function reSize(width = null, height = null) {
        player.reSize(width, height);
    }

    async function destroy(domContainer) {
        return new Promise(async (resolve, reject) => {
            if (player) {
                let div = document.getElementById(domContainer)
                while (div && div.firstChild) {
                    div.removeChild(div.firstChild);
                }
                player = null;
                resolve()
            } else {
                resolve()
            }
        })
    }

    function portraitCloudNumbers() {

        return ['G16987252',
            'G16987230',
            'G16987523',
            'G16987311',
            'G16987227',
            'G16987429',
            'G16987239',
            'G16987260',
            'G16987254',
            'G16987370',
            'G16987306',
            'G16987353',
            'G16987203',
            'G16987277',
            'G16987232',
            'G16987183',
            'G16987319',
            'G16987248',
            'G16987297',
            'G16987262',
            'G16987176',
            'E52043539',
            'G16987316',
            'E27755128',
            'G58098501',
            'G58097224',
            'G51542118',
            'G32201173',
            'G38364079',
            'G32200974',
            'G32201166',
            'G32200947',
            'G32219512',
            'G32201165',
            'G09017034',
            'G09017965',
            'G09017968',
            'G09017837',
            'G09018123',
            'G09018185',
            'G14729700',
            'G09017835',
            'G09017974',
            'E27755103',
            'G16987795',
            'G16987380',
            'G16987245',
            'G16987666',
            'E06412927',
            'E52043718',
            'G16987206',
            'G16987166',
            'G16987293',
            'G16987242',
            'G16987303',
            'G16987439'
        ]
    }
    return {
        playVideo() {
            return playVideo()
        },
        stopVideo() {
            return stopVideo()
        },
        startTalk() {
            return startTalk()
        },
        stopTalk() {
            return stopTalk()
        },
        startRecord() {
            return startRecord()
        },
        stopRecord() {
            return stopRecord()
        },
        capturePicture() {
            return capturePicture()
        },
        fullScreen() {
            return fullScreen()
        },
        initDisplay(domContainer, accessToken, serialNum, option = {}, callback) {
            return initDisplay(domContainer, accessToken, serialNum, option, callback)
        },
        destroy(domContainer) {
            return destroy(domContainer)
        },
        calcSizeBySerialNum(serialNum, clientWidth, clientHeight) {
            return calcSizeBySerialNum(serialNum, clientWidth, clientHeight)
        }


    }
}